import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import VueHead from 'vue-head'

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/service_guide.css'


const app = createApp(App)
app.use(router)
//app.use(VueHead)
app.mount('#app')

//createApp(App).mount('#app')

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)


import { createRouter, createWebHistory } from 'vue-router'
import SpotDetails from './components/SpotDetails.vue'
import service_guide from './components/ServiceGuide.vue'
import serviceTop from './components/serviceTop.vue'
import NotFoundComponent from './components/404.vue'

const routes = [
  { path: '/:areaCode/:locationId', name: 'spotDetails', component: SpotDetails, meta: { canonicalURL: 'https://bygo.jp/:areaCode/:locationId' } },
  { path: '/', name: 'SpotDetails', component: service_guide },
  { path: '/service', name: 'serviceTop', component: serviceTop },
  { path: '/:pathMatch(.*)*', name: 'NotFoundComponent', component: NotFoundComponent },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})


router.afterEach((to) =>{
	//const title = to.meta.title + "|" + 'BYGO'
	//document.title = title
	//document.querySelector("meta[name='description']").setAttribute('content', to.meta.desc)

   // 基本URLを設定
  /*const baseURL = 'https://bygo.jp';
  let canonicalURL;

  // metaにcanonicalURLが設定されている場合は、その値を使用し、パラメータを置換
  if (to.meta.canonicalURL) {
    canonicalURL = to.meta.canonicalURL.replace(':areaCode', to.params.areaCode).replace(':locationId', to.params.locationId);
  } else {
    // metaにcanonicalURLがない場合は、パスからURLを生成
    canonicalURL = `${baseURL}${to.path}`;
  }

  updateCanonicalURL(canonicalURL);*/
})

/*function updateCanonicalURL(url) {
  // 既存のCanonicalタグを検索し、存在する場合は削除
  const existingLink = document.querySelector('link[rel="canonical"]');
  if (existingLink) {
    document.head.removeChild(existingLink);
  }

  // 新しいCanonicalタグを作成して設定
  const link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', url);
  document.head.appendChild(link);
}*/

export default router
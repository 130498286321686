<template>
<footer class="footer">
    <div class="container">
      <div class="row footer_t">
        
       <nav class="footer-l-sub">
         <ul class="footer-l-sub">
          <li class="footer_t_p">
           <a href="https://fjy.notion.site/69bc08e69e3e4c2daef1cd7e9a5af42b?pvs=4" class="t_black">利用規約</a>
          </li>
          <li class="footer_t_p">
           <a href="https://fjy.notion.site/400069c768ae49018ca989b9a37412b3?pvs=4" class="t_black">プライバシーポリシー</a>
          </li>
         </ul>
        </nav>
      </div>
        <p id="copyright">copyright ©︎2023 bygo</p>
      </div>
  
  </footer>

  </template>
<script >
  export default {
    async mounted() {
      window.addEventListener('scroll', this.handleScroll);
        document.title = 'BYGO - さあ歴史を見つけよう';
        this.setMetaDescription('BYGO（バイゴー）は、まだ知らないまちの歴史を見つけ、みんなでシェアするサービスです。近くの史跡や歴史の情報を探す、歴史の情報を記録する、歴史の情報をシェアすることができます');
    },
    methods: {
      setMetaDescription(content) {
          const meta = document.querySelector('meta[name=description]') || document.createElement('meta');
          meta.setAttribute('name', 'description');
          meta.setAttribute('content', content);
          document.head.appendChild(meta);
        }
    }
  }
</script>

<template>
	<main>
      <div class="img_top">
        
        <h2 class="text-xl-start text_jp">まだ知らない<br class="sp">このまちの<br class="sp">歴史をみつける。</h2>
        
        <div>
          <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/img_app_fv_1600.png" class="app_fv">
        </div>

        <div class="cta">
          <a href="https://apps.apple.com/jp/app/bygo-%E3%81%95%E3%81%82%E6%AD%B4%E5%8F%B2%E3%82%92%E8%A6%8B%E3%81%A4%E3%81%91%E3%82%88%E3%81%86/id1568929579" class="a_line">
           <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/the_App_Store_Badge_US-UK_blk_092917.png" class= "apple">
         </a>
           <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/icon_200px.png" class="icon">
         
        </div>
      </div>

      <div class="container">
        <p class="category_subtitle text_JP">使い方</p>
        <h2 class="category_title text_EN ">HOW TO USE</h2>
      </div>

      <div class="container app_popouse_circle">
          <div class="row ">
            <div class="col text-center">
              <h3 class="fv_subtitle_3 text_jp">近くの歴史を<br class="sp">見つける</h3>
              <p class="use_guide">いま住むまちや、訪れたまちで、歴史を見つけて、新しい発見をするのは楽しむ</p>
            </div>
            <div class="col">
             <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/img_app_top_700px.png" class="app_img">
            </div>
          </div>
      </div>

      <div class="container app_popouse_rectangle">
          <div class="row ">
          <div class="col">
             <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/img_app_post_700px.png" class="app_img">
            </div>
            <div class="col text-center">
              <h3 class="fv_subtitle_3 text_jp">記録にする</h3>
              <p class="use_guide">訪れた記念に、感じたことを記録に残しておく。また振り返ってみて、他の歴史とつなげてみて楽しむ機会に</p>
            </div>
            
          </div>
      </div>
      <div class="container app_popouse_rec-double">
          <div class="row ">
            <div class="col text-center">
              <h3 class="fv_subtitle_3 text_jp">シェアする</h3>
              <p class="use_guide">まだ見ぬ・訪れたことがない人も、あなたのシェアをみて訪れる歴史があるかも。つながることで歴史の話が増える</p>
            </div>
            
            <div class="col">
             <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/img_app_share_700px.png" class="app_img">
            </div>
            
          </div>
      </div>

      <div class="container b_under">
      
      <h2 class="text-center text_JP sub_title">さあ、歴史をみつけよう</h2>
      <div class="dl_icon">
        <a href="https://apps.apple.com/jp/app/bygo-%E3%81%95%E3%81%82%E6%AD%B4%E5%8F%B2%E3%82%92%E8%A6%8B%E3%81%A4%E3%81%91%E3%82%88%E3%81%86/id1568929579">
        <img src="https://storage.googleapis.com/history-d8428.appspot.com/base/the_App_Store_Badge_US-UK_blk_092917.png" class="footer_apple">
      </a>

      </div>
      
      </div>

    </main>
</template>
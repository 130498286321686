<script >
import Firebase from "../firebase_settings/index.js";
import { collection, query, where, doc, getDoc, getDocs, getCountFromServer, limit } from "firebase/firestore";
import { ref as fbRef, getDownloadURL } from "firebase/storage";
import { useRoute } from "vue-router";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from 'vue'
import index from '/config/algolia'
import config from '/config/config.js'

const storage = Firebase.storage

export default {
	name: "getSpotDetails",
	components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  watch: {
    '$route'() {
      // ルートが変わったときに実行されるメソッド
      this.$router.go();
    }
  },

	async mounted() {

      	await Promise.all([
      		this.getLocations(),
      		this.getSpotDetails(),
      		this.getDownload(),
      		this.getContents(),
      		this.loadMapKitScript(),
      		
      	])

      	window.addEventListener('scroll', this.handleScroll);
      	document.title = this.spotName + ' - さあ歴史を見つけよう BYGO';
      	this.setMetaDescription(this.spotName+'('+ this.spotNameKana +')' +' | BYGO（バイゴー）は、まだ知らないまちの歴史を見つけ、みんなでシェアするサービスです。近くの史跡や歴史の情報を探す、歴史の情報を記録する、歴史の情報をシェアすることができます');
      	 this.setCanonicalURL(`https://bygo.jp/${this.$route.params.areaCode}/${this.$route.params.locationId}`);
      	 this.setPageTitleAndDescription();
  },

	
	data() {

        return {
        	spotName: "",
        	spotNameKana: "",
        	spotDescription: "",
        	spotWiki: "",
        	spotGeopointLat: "",
        	spotGeopointLon: "",
        	objectArray: [],
        	locationArray: [],
        	map: null,
        	query: '',
        	hits: [],
        	isScrolling: false,
        	scrollTimeout: null,
        	isLoading: true,
        }
      },

      beforeDestroy() {
      	
      	// 動的に追加されたスクリプトタグを削除
      	const mapKitScript = document.getElementById('mapkit-script');
      	if (mapKitScript) {
      		mapKitScript.remove();
      	}

      	window.removeEventListener('scroll', this.handleScroll);
      	clearTimeout(this.scrollTimeout);
      },



      methods: {

      	async getContents() {
    

    this.objectArray = [];
    
    const route = useRoute();      		
    const db = Firebase.db;   
    const q = query(collection(db, "checkIn"),
        where("locationId", "==", route.params.locationId),
        where("detail","==", true),
        where("state","==", true),
        limit(10)
    );

    const querySnapshot = await getDocs(q);

    for (const docSnapshot of querySnapshot.docs) {
        const master = docSnapshot.data();
        const locationId = master.locationId;
        const userId = master.userId;

        // Locationのタイトルを取得
        let title = "";
        try {
            const locationDocRef = doc(db, "locations", locationId);
            const locationDoc = await getDoc(locationDocRef);
            if (locationDoc.exists()) {
                title = locationDoc.data().name;
                console.log(title);
            }
        } catch (error) {
            console.error("Error getting location title:", error);
        }

        // Userの名前を取得
        let name = "非公開";
        let userImageURL = '/userIcon/no_image.png'; // デフォルトの画像
        try {
            const userDocRef = doc(db, "accounts", userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                name = userDoc.data().name;
                const uImage = userDoc.data().uImage;
                if (uImage) {
                    const starsRef = fbRef(storage, 'userIcon/' + uImage);
                    userImageURL = await getDownloadURL(starsRef);
                }
            }
        } catch (error) {
            console.error("Error getting user name:", error);
        }

        this.objectArray.push({
            title: title, 
            content: master.post, 
            name: name,
            userImageURL: userImageURL
        });
    }

    if (querySnapshot.empty) {
        // データが存在しない場合の処理
        this.objectArray.push({
            title: "", 
            content: "投稿がまだありません",
            name: "no name",
            userImageURL: 'https://storage.googleapis.com/history-d8428.appspot.com/base/UserIcon_200.png'
        });
    }
	},
      	setCanonicalURL(url) {
      		const link = document.querySelector('link[rel="canonical"]') || document.createElement('link');
      		link.setAttribute('rel', 'canonical');
      		link.setAttribute('href', url);
      		document.head.appendChild(link);
      	},

      	setMetaDescription(content) {
      		const meta = document.querySelector('meta[name=description]') || document.createElement('meta');
      		meta.setAttribute('name', 'description');
      		meta.setAttribute('content', content);
      		document.head.appendChild(meta);
      	},

      	handleScroll() {
      		this.isScrolling = true;
      		// スクロールが停止してから一定時間後に状態をリセット
      		clearTimeout(this.scrollTimeout);
      		this.scrollTimeout = setTimeout(() => {
      			this.isScrolling = false;
      			}, 100); // 100ミリ秒後に非スクロール状態に戻る
      	},  	

      	search() {

      		const filters = 'state:true';

      		index.search(this.query, { filters }).then(({ hits }) => {
      			this.hits = hits;
      		});
      	},

      	navigate(url) {
      		
      		if (this.$route.path !== url) {
      			
      			try {
      				
      				this.$router.push({path: url}).then(() => {
      					this.$router.go();
      				});
      			}catch (error) {
      				console.error('エラーが発生しました:', error);
      				// ここにエラーハンドリングの処理を書く
      				

      			}
      		};
      	},

      	async loadMapKitScript() {

      		// MapKit JSスクリプトのURL
      		const mapKitScriptUrl = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';

      		const route = useRoute();
      		const db = Firebase.db

      		getDoc(doc(db, "locations", route.params.locationId)).then(docSnap => {
      				if (docSnap.exists()) {

      					const master = docSnap.data();

      					// スクリプトタグを作成
      					const script = document.createElement('script');
      					script.src = mapKitScriptUrl;
      					script.onload = () => this.initializeMap(master.name,master.geopoint["_lat"],master.geopoint["_long"]); // スクリプトが読み込まれた後にマップを初期化

      					// スクリプトタグをドキュメントに追加
      					document.head.appendChild(script);


      				} else {
      					console.log("No such document!");
      				}
      			});
      	},

      	initializeMap(name,lat, long) {


      		// MapKitの初期化
      		mapkit.init({
      			authorizationCallback: function(done) {
      			// ここにMapKit JSのキーを設定
      				
      			done(config.secretToken);

        		},
        		
      		});
      		this.createMap(name, lat, long);
      	},

      createMap(name, lat, long){

      	// 東京駅の座標
      	const initialCoordinate = new mapkit.Coordinate(lat, long); // 例：東京駅の緯度経度
      	const initialSpan = new mapkit.CoordinateSpan(0.01, 0.01); // 地図の縮尺を指定
      	const initialRegion = new mapkit.CoordinateRegion(initialCoordinate, initialSpan);

      	// 地図の初期設定
      	const map = new mapkit.Map('map', {
        	region: initialRegion,
        });

        // 東京駅にピンを追加
        const pin = new mapkit.MarkerAnnotation(initialCoordinate, {
        	title: name
        });
        map.addAnnotation(pin);

        if (map.addAnnotation(pin) != null){
        	this.map.showItems([pin]);
        }
      },
      	async getLocations(){

      		this.locationArray = []
      		const route = useRoute();

      		
      		const db = Firebase.db   
      		const q = query(collection(db, "locations"), 
      			where("area_code", "==", route.params.areaCode),
      			where("state", "==", true), limit(10),
      			);
      		const querySnapshot = await getDocs(q);

      		await querySnapshot.forEach((doc) => {
      			const master = doc.data();

      		
      			const starsRef = fbRef(storage, 'images200*200/'+ master.image["200"]);
      			
      			getDownloadURL(starsRef).then((url) => {

      				this.locationArray.push({
      					title:master.name, 
      					img_url:url,
      					area_code: master.area_code,
      					id:doc.id,
      					url: "/" + master.area_code + "/" + doc.id

      				})

      			}).catch((error) => {

      				this.locationArray.push({
      					title:master.name,
      					img_url: 'https://storage.googleapis.com/history-d8428.appspot.com/base/no%20image.png',
      					url: "/" + master.area_code + "/" + doc.id
      				})

      			});
      		});
      	},

      	async getDownload() {

      		const route = useRoute();
      		const db = Firebase.db

      		getDoc(doc(db, "locations", route.params.locationId)).then(docSnap => {
      				if (docSnap.exists()) {

      					const master = docSnap.data();

      					//画像の表示
      					const starsRef = fbRef(storage, 'images600*600/'+ master.image["600"]);

      					getDownloadURL(starsRef).then((url) => {
      						const xhr = new XMLHttpRequest();
      						xhr.responseType = 'blob';
      						xhr.onload = (event) => {
      							const blob = xhr.response;
      						};
      						xhr.open('GET', url);
      						xhr.send();
      						// Or inserted into an <img> element
      						const img = document.getElementById('myimg');
      						img.setAttribute('src', url);

      					}).catch((error) => {
      						
      						const img = document.getElementById('myimg');
      						img.setAttribute('src', '/img/no_image.png');

      					});

      				} else {
      					console.log("No such document!");
      				}
      			})
      	},
      	async getSpotDetails() {

      		this.spotName = ""
      		this.spotNameKana = ""
      		this.spotDescription = ""
      		this.spotGeopointLat = ""
      		this.spotGeopointLon = ""
      		this.Slide = ""
      		this.spotAreaCode = ""
      		this.areaCode = ""

      		try {

      			const route = useRoute();
      			const db = Firebase.db

      			getDoc(doc(db, "locations", route.params.locationId)).then(docSnap => {
      				if (docSnap.exists()) {

      					const master = docSnap.data();

      					this.spotName = master.name
      					this.spotNameKana = master.name_kana
      					this.spotDescription = master.description
      					this.spotGeopointLat = master.geopoint["_lat"]
      					this.spotGeopointLon = master.geopoint["_long"]
      					
      					const spotWiki = document.getElementById('wiki')
      					spotWiki.setAttribute('href', master.wiki);

      					if (route.params.areaCode != master.area_code) {
      						this.$router.replace({ name: 'NotFoundComponent' });
      					}

      				} else {
      					
      					this.$router.replace({ name: 'NotFoundComponent' });
      
      				}
      			})
      		} catch(error) {
      			console.log("No such document!");
      		}
      	}
      }      
}

</script>


<template>
	<main id="main_all">
		<div class="container main_flame">
			<div class="row">
				<div class="col col-md-6 col-12">
					<div id="search">
						<div id="banner">
							<a href="https://fjy.notion.site/BYGO-2b6339a1db8b4203b3b1794d00a971b0">
								<img src="https://storage.googleapis.com/history-d8428.appspot.com/base/guide_checkIn_700.png" class="banner">
							</a>

					</div>
						<h2 id="search_title">検索</h2>
						<div>
							<input v-model="query" @input="search" id="search_box" placeholder="検索するテキストを入力...">
							<ul id="search_area">
								<li v-for="item in hits" :key="item.objectID" class="search_list">
									<router-link :to="{ path:`/${item.areaCode}/${item.objectID}`}">
									{{ item.name }}
									</router-link>
								</li>
							</ul>
						</div>

					</div>
					
				</div>
				<div class="col col-md-6 col-12">
					<div class="main_img">
						<img src="#" id="myimg"/>	
					</div>
					<div class="title_flame">
						<h1 class="text_title">{{ spotName }}</h1>
						<p class="text_kana">{{ spotNameKana }}</p>
					</div>
					<div class="description_flame">
						<p class="text_description">{{ spotDescription }}</p>
						<a href="#" id="wiki">wikipedia</a>
					</div>

					<div id="map"></div>

					<h3 class="sub-caption">このスポットのシェア</h3>
						<Carousel :itemsToShow="1.5" > <!--:autoplay="3000" :wrap-around="true">-->
							
							<Slide v-for="slide in objectArray" :key="objectArray">
							<div class="carousel__item description_flame">
								
								<div class="carousel_title_area">
									<h3 class="carousel_title">{{ slide.title }}</h3>
									<p class="carousel_text">{{ slide.content }}</p>
								</div>
								<div class="carousel_content">
									
								　
								</div>
							</div>
							<div class="carousel_accounts">
								<img :src="slide.userImageURL" class="carousel_user_image" />
								<p class="carousel_name">{{ slide.name }}</p>
							</div>
							
						</Slide>
						<template #addons>
							<Navigation />
							<!--<Pagination />-->
						</template>
					</Carousel>

					<h3 class="sub-caption">近くのスポット</h3>

					<Carousel  :itemsToShow="1.5" >
							<Slide v-for="slide in locationArray" :key="locationArray" @click="navigate(slide.url)" class="test" >
								<div class="carousel_item_spot">
							
							<img :src="slide.img_url" class="img_carousel" />
							<p class="carousel_name">{{ slide.title }}</p>
							</div>
						</Slide>
						<template #addons>
							<Navigation />
							<!--<Pagination />-->
						</template>
					</Carousel>

				</div>

			</div>
			<div v-if="!isScrolling" id="app_install">
				<div id="logo"></div>
					<div id="install_button">
						<a id="install_text" href="https://apps.apple.com/jp/app/bygo-%E3%81%95%E3%81%82%E6%AD%B4%E5%8F%B2%E3%82%92%E8%A6%8B%E3%81%A4%E3%81%91%E3%82%88%E3%81%86/id1568929579">アプリをインストール</a>
					</div>	
			</div>
    </div>
    
  </main>

</template>


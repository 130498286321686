<script>
import Firebase from "../firebase_settings/index.js";
import { collection, query, where, doc, getDoc, getDocs, getCountFromServer, limit } from "firebase/firestore";
import { ref as fbRef, getDownloadURL } from "firebase/storage";
import { useRoute } from "vue-router";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from 'vue'
import index from '/config/algolia'
import config from '/config/config.js'

const storage = Firebase.storage

export default {
	name: "serviceTop",
	components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  watch: {
    '$route'() {
      // ルートが変わったときに実行されるメソッド
      this.$router.go();
    }
  },
}
</script>